.title-svg-gradient {
	--stop-color-radial: theme("colors.gray.50");
	--stop-color-bottom: theme("colors.gray.50");
	--stop-color-top: theme('colors.gray.50');

	&::after {
		background: linear-gradient(to bottom, transparent 60%, var(--stop-color-bottom)),
			linear-gradient(to top, transparent 70%, var(--stop-color-top));
	}

	&::before {
		background: radial-gradient(circle at center, transparent 40%, var(--stop-color-radial));
		z-index: 10;
	}

	@screen sm {
		--stop-color-radial: white;
		--stop-color-bottom: white;
		--stop-color-top: transparent;
	}
}
