.questionnaire-form-item-upload-dragger {
	@apply px-8 py-6 relative rounded-lg overflow-hidden;

	&::before {
		@apply absolute inset-0 bg-emerald-50 z-[-1] transition-[width];
        width: 0;
        content: unset;
	}

    &[data-uploading=true]::before {
        content: '';
    }

    @for $i from 0 through 100 {
        &[data-progress='#{$i}']::before {
            width: calc($i * 1%);
        }
    }
}
